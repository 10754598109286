import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Hello, world!</h1>
        <p>This project successfully built with github actions, Raspberry Pi actions runner scale set, and docker buildx multi-arch!</p>
      </div>
    );
  }
}
