import React, { Component } from 'react';

export class RecipeCard extends Component {
    static displayName = RecipeCard.name;
    
    render(){
        return (
            <div>
                <h3>{this.props.recipe.name}</h3>
                <div>{this.props.recipe.description}</div>
                <table>
                {this.props.recipe.ingredients.map(ing=>
                    <tr>
                      <td>{ing.amount.value} {ing.amount.unit}</td>
                      <td>{ing.item.name}</td>  
                    </tr>
                )}
                </table>
            </div>
        );
    }
}