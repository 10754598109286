import React, { Component } from 'react';
import {RecipeCard} from "./RecipeCard";

export class RecipeList extends Component {
    static displayName = RecipeList.name;

    constructor(props) {
        super(props);
        this.state = { recipes: [], loading: true };
    }

    componentDidMount() {
        this.populateRecipes();
    }

    static renderRecipes(recipes) {
        return (
            <div>
                {recipes.map(recipe=>
                    <RecipeCard recipe={recipe} />
                )}
            </div>
        );
    }
    
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : RecipeList.renderRecipes(this.state.recipes);

        return (
            <div>
                <h1 id="tableLabel">Recipe List</h1>
                {contents}
            </div>
        );
    }

    async populateRecipes() {
        const response = await fetch('api/recipes');
        const data = await response.json();
        this.setState({ recipes: data, loading: false });
    }
}